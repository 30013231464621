<template>
    <div>
        <v-card outlined>
             <v-card-title class="title">
                Attendance Pending Report
                <v-spacer></v-spacer>
                <!-- <download-button v-if="form.items.data.length" @click="printAttendance()">Download Attendance Report</download-button> -->
                <print-buttons :downloadUrl="printUrl" :passAuthToReport='true' :pdf="false" :excel="true" label="Download Attendance Pending Report"
                               v-if="data.length > 0">
                            </print-buttons>
            </v-card-title>



            <v-card-title class="title pb-0">
                <v-flex xs4 style="margin-bottom:26px;">
                    <v-calendar-field
                            id-val="attendance_date"
                            v-model="attendance_date"
                            label="Attendance Date">

                    </v-calendar-field>
                </v-flex>
                <v-flex xs4>
                    <v-btn color="primary" class="mt-n8" outlined @click="get()">Search</v-btn>
                </v-flex>
            </v-card-title>
        </v-card>
        <v-container>
            <template>
                <v-layout wrap>
                    <v-flex xs12>
                        <v-data-table
                            :headers="headers"
                            :items="form.items.data"
                            :loading="form.loading"
                            hide-default-footer
                            :server-items-length="50">
                            <template  v-slot:item="{index, item}">
                                <tr>
                                    <td>{{  ++index  }}</td>
                                    <td class="text-xs-left">{{ item.grade
                                        }}<sup>"{{item.section}}"</sup></td>
                                    <td class="text-xs-left">{{ item.teacher_name }}
                                    </td>
                                    <td class="text-xs-left">{{ item.primary_mobile }}
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-flex>
                </v-layout>
            </template>
        </v-container>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'
    import {getPropertyFromArrayObject, nepNumberToEng, engNumberToNep} from '@/library/helpers'
    import {validateDate} from "../../../../../library/helpers";

    const NepaliDate = require('nepali-date');


    export default {
        data: () => ({
            form: new Form({}, '/api/report/academic/attendance-done-teacher'),
            grades: [],
            printUrl: '',
            loadingGrade: false,
            loadingSection: false,
            attendance_date: new NepaliDate(new Date()).format('YYYY-MM-DD'),

            data: [],
            rowsPerPageItems: [5, 10, 25, 50, 75],
            pagination: {
                rowsPerPage: 10,
                sortBy: 'grade',
            },
            headers: [
                {text: '#', align: 'center', value: 'id', width: 50, sortable: false},
                {text: 'Grade', align: 'left', value: 'grade', sortable: false},
                {text: 'Teacher Name', align: 'left', value: 'teacher_name', sortable: false},
                {text: 'Mobile', align: 'left', value: 'primary_mobile', sortable: false},
            ],
            dateValidation: [
                (v) => validateDate(v) || 'Invalid Date Format',
            ],
        }),

        computed: {
            ...mapState(['batch']),
        },
        mounted() {
        },
        watch: {
            'attendance_date': function (v) {
                this.form.items.data = [];
                // if (v && v.length === 10 && validateDate(v)) this.get();
            },
            'pagination': function (v) {
                this.get();
            },
        },
        methods: {
            nepNumberToEng,
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&') + '&attendanceDate=' + this.attendance_date;
            },


            get(params) {
                if (this.attendance_date) {
                    let query = [null, undefined].includes(params) ? this.queryString() : params;
                    this.form.get(null, query).then(({data}) => {
                        // this.data = data.students;
                        this.data = data.data;
                        this.printUrl = data.download_url;
                    });
                }
            },

            printAttendance() {
                window.open(this.printUrl + 'pdf');
            },
            

        }
    }
</script>